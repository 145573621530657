<template>
  <div>

    <v-tooltip top color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="newEntry()">
          <v-icon
            color="primary"
          >
            fal fa-plus-circle
          </v-icon>
        </v-btn>
      </template>
      <span>Add Template</span>
    </v-tooltip>

    <!--Entry-->
    <v-dialog v-model="show" scrollable persistent
              max-width="1400">
      <v-card flat class="white" height="900">
        <v-card-title class="text-h5 secondary--text">Template Details<v-spacer /><v-btn text rounded outlined @click="cancelEntry()"><v-icon
            small left>fal fa-times</v-icon>Cancel</v-btn></v-card-title>
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" @submit.prevent="saveEntry()" lazy-validation>
            <v-container fluid>
              <v-row dense>

                <!--Left Side-->
                <v-col cols="12" sm="3" class="px-4">
                  <v-row dense>
                    <!--Name-->
                    <v-col cols="12" sm="12">
                      <v-textarea
                        v-model="d_name"
                        label="Name"
                        placeholder=" "
 persistent-placeholder
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Section-->
                    <v-col cols="12" sm="12">
                      <v-select
                        :items="valueListsStore.correspondenceSections"
                        item-text="value"
                        item-value="id"
                        label="Section"
                        placeholder=" "
 persistent-placeholder
                        v-model="f_section"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Type-->
                    <v-col cols="12" sm="12">
                      <v-select
                        :items="valueListsStore.correspondenceTypes"
                        item-text="value"
                        item-value="id"
                        label="Type"
                        placeholder=" "
 persistent-placeholder
                        v-model="f_type"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row v-if="f_type === 2" dense>
                    <!--Letterhead-->
                    <v-col cols="12" sm="12">
                      <v-select
                        :items="valueListsStore.letterheadType"
                        item-text="value"
                        item-value="id"
                        label="Letterhead"
                        placeholder=" "
 persistent-placeholder
                        v-model="f_letterhead"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense> <!--BG 6/4/2021-->
                    <!--Language-->
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        :items="valueListsStore.valueListItems[15]"
                        item-text="d_name"
                        item-value="d_name"
                        label="Language"
                        placeholder=" "
 persistent-placeholder
                        v-model="d_language"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Care Plan Selector-->
                    <v-col cols="12" sm="12">
                      <v-select
                        :items="valueListsStore.yesNo"
                        item-text="value"
                        item-value="id"
                        label="Care Plan Selector"
                        placeholder=" "
 persistent-placeholder
                        v-model="f_selectorCarePlans"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Care Plan Option Selector-->
                    <v-col cols="12" sm="12">
                      <v-select
                        :items="valueListsStore.yesNo"
                        item-text="value"
                        item-value="id"
                        label="Care Plan Option Selector"
                        placeholder=" "
 persistent-placeholder
                        v-model="f_selectorCarePlanOptions"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Provider Contacts Selector-->
                    <v-col cols="12" sm="12">
                      <v-select
                        :items="valueListsStore.yesNo"
                        item-text="value"
                        item-value="id"
                        label="Provider Contacts Selector"
                        placeholder=" "
 persistent-placeholder
                        v-model="f_selectorProviderContacts"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Status-->
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        :items="valueListsStore.activeStatus"
                        label="Status"
                        placeholder=" "
 persistent-placeholder
                        item-text="value"
                        item-value="id"
                        v-model="f_status"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <!--Center-->
                <v-col cols="12" sm="6" class="px-4">
                  <v-row dense>
                    <!--Subject-->
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="d_subject"
                        label="Subject"
                        placeholder=" "
 persistent-placeholder
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Body-->
                    <v-col cols="12" sm="12">
                      <v-textarea
                        rows=24
                        ref="textarea"
                        auto-grow
                        v-model="d_body"
                        label="Body"
                        placeholder=" "
 persistent-placeholder
                       />
                    </v-col>
                  </v-row>
                </v-col>

                <!--Right Side-->
                <v-col cols="12" sm="3" class="px-4">
                  <v-row dense>
                    <!--Section-->
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        :items="mergeCodeSectionsStore.mergeCodeSectionsValueList"
                        label="Merge Code Type"
                        placeholder=" "
 persistent-placeholder
                        item-text="d_name"
                        item-value="id"
                        v-model="mergeCodeSectionID"
                        clearable
                        clear-icon="fal fa-times-circle"
                        @input="pullMergeCodes(mergeCodeSectionID)"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense v-if="mergeCodesStore.mergeCodesValueList.length > 0">
                    <v-list dense>
                      <template v-for="(item, index) in mergeCodesStore.mergeCodesValueList">
                        <v-list-item
                          :key="item.id"
                          @click="addMergeCode(item.d_code)"
                        >
                          <v-list-item-content>
                            <v-list-item-title>
                              <span>{{item.d_code}}</span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider :key="index" />
                      </template>
                    </v-list>
                  </v-row>

                  <v-row v-else dense>
                    <text-display :displayLabel="'Merge Codes'" :displayString="'Select Merge Code Type'" />
                  </v-row>
                </v-col>
              </v-row>

            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn block rounded color="primary" @click="saveEntry()"><v-icon
            small left>fal fa-check</v-icon>Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import { mixin } from '@/mixins/mixin.js';
import TextDisplay from '@/components/TextDisplay';

const { mapFields } = createHelpers({
  getterType: 'templatesStore/getEntryField',
  mutationType: 'templatesStore/updateEntryField',
});

export default {
  name: 'TemplateEntry',
  mixins: [mixin],
  components: {
    TextDisplay,
  },
  computed: {
    ...mapState({
      templatesStore: (state) => state.templatesStore,
      valueListsStore: (state) => state.valueListsStore,
      mergeCodesStore: (state) => state.mergeCodesStore,
      mergeCodeSectionsStore: (state) => state.mergeCodeSectionsStore,
    }),
    ...mapFields([
      'show',
      'valid',
      'mode',
      'id',
      'd_name',
      'd_subject',
      'd_body',
      'd_language',
      'f_selectorCarePlans',
      'f_selectorCarePlanOptions',
      'f_selectorProviderContacts',
      'f_section',
      'f_letterhead',
      'mergeCodeSectionID',
      'f_type',
      'f_status',
    ]),
  },
  data() {
    return {
      types: [
        {
          id: 1,
          name: 'Email',
        },
        {
          id: 2,
          name: 'Letter',
        },
      ],
    };
  },
  methods: {
    async newEntry() {
      await this.$store.dispatch('mergeCodesStore/clearValueList');
      await this.$store.dispatch('mergeCodeSectionsStore/valueList');
      const Language_data_cd = { //BG 6/4/2021 (4 lines) code placed to populate valueListItems store
        id: [15],
      };
      await this.$store.dispatch('valueListsStore/items', Language_data_cd);
      this.valid = true;
      this.mode = 1;
      this.id = '';
      this.d_name = '';
      this.d_subject = '';
      this.d_body = '';
      this.d_language = '';
      this.f_selectorCarePlans = '';
      this.f_selectorCarePlanOptions = '';
      this.f_selectorProviderContacts = '';
      this.f_section = '';
      this.f_letterhead = '';
      this.mergeCodeSectionID = '';
      this.f_type = '';
      this.f_status = 1;
      this.show = true;
    },

    cancelEntry() {
      this.show = false;
    },

    async pullMergeCodes(id) {
      await this.$store.dispatch('mergeCodesStore/clearValueList');
      const data = {
        f_section: id,
      };
      await this.$store.dispatch('mergeCodesStore/valueList', data);
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters['templatesStore/getEntry'];
        /* Create */
        if (this.mode === 1) {
          await this.$store.dispatch('templatesStore/create', entry);
        }
        /* Edit */
        else {
          await this.$store.dispatch('templatesStore/update', entry);
        }
        this.cancelEntry();
      }
    },

    addMergeCode(code) {
      const insertText = code;
      if (!insertText.length) return;

      const textarea = this.$refs.textarea.$refs.input;
      const sentence = textarea.value;
      const len = sentence.length;
      let pos = textarea.selectionStart;
      if (pos === undefined) {
        pos = 0;
      }

      const before = sentence.substr(0, pos);
      const after = sentence.substr(pos, len);

      this.d_body = before + insertText + after;

      this.$refs.textarea.focus();
      this.$refs.textarea.select();

      this.$nextTick().then(() => {
        textarea.selectionStart = pos + insertText.length;
      });
    },
  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>

<template>
  <div>
    <!--Title Bar-->
    <title-bar :title-value="'Templates'" />

    <!-- Filter -->
    <v-toolbar flat dense>
      <!--  Filter Icon -->
      <v-menu v-model="filterMenu" offset-y right :close-on-content-click="false">
        <template #activator="{ on }">
          <v-btn rounded color="primary" v-on="on"
            ><v-icon small left>fal fa-filter</v-icon><span v-if="showFiltered()">Filtered</span
            ><span v-else>Filter</span>
          </v-btn>
        </template>

        <v-card width="400px">
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12" sm="12" v-if="showFiltered()">
                  <v-btn block rounded color="primary" @click="clearFilter()">
                    <v-icon small left>fal fa-times</v-icon>Clear Filters
                  </v-btn>
                </v-col>

                <!-- Name -->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="d_name"
                    label="Name"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>

                <!--Type-->
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="valueListsStore.correspondenceTypes"
                    label="Type"
                    placeholder=" "
                    persistent-placeholder
                    item-text="value"
                    item-value="id"
                    v-model="f_type"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>

                <!--Section-->
                <v-col cols="12" sm="12">
                  <v-select
                    :items="valueListsStore.generalSections"
                    item-text="value"
                    item-value="id"
                    label="Section"
                    placeholder=" "
                    persistent-placeholder
                    v-model="f_section"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>

                <!--Language-->
                <!--BG 6/4/2021-->
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="valueListsStore.valueListItems[15]"
                    item-text="d_name"
                    item-value="d_name"
                    label="Language"
                    placeholder=" "
                    persistent-placeholder
                    v-model="d_language"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn block rounded color="primary" @click="filter()">
              <v-icon small left>fal fa-check</v-icon>Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-spacer />

      <!--Entry Dialog-->
      <template-entry />
    </v-toolbar>

    <!--Pagination-->
    <pagination
      :show="templatesStore.templates.data && templatesStore.templates.data.length > 0"
      :showTotal="true"
      :currentPage="templatesStore.templates.current_page"
      :lastPage="templatesStore.templates.last_page"
      :total="templatesStore.templates.total"
      v-on:paginate-event="filter($event)"
    />

    <div v-if="progressStore.progress !== true">
      <!--List-->
      <list-basic
        :show="templatesStore.templates.data && templatesStore.templates.data.length > 0"
        :list-data="templatesStore.templates.data"
        :title="'Templates'"
        :section="14"
        @open-entry="openEntry($event)"
        @open-entry-duplicate="openEntry($event, 1)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TitleBar from "@/components/TitleBar";
import TemplateEntry from "@/components/TemplateEntry";
import Pagination from "@/components/Pagination";
import ListBasic from "@/components/ListBasic";

const { mapFields } = createHelpers({
  getterType: "templatesStore/getFilterField",
  mutationType: "templatesStore/updateFilterField"
});

export default {
  name: "Templates",
  components: {
    TitleBar,
    TemplateEntry,
    Pagination,
    ListBasic
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      templatesStore: state => state.templatesStore,
      valueListsStore: state => state.valueListsStore,
      progressStore: state => state.progressStore,
      mergeCodeSectionsStore: state => state.mergeCodeSectionsStore
    }),
    ...mapFields(["page", "d_name", "f_type", "f_section", "d_language"])
  },
  data() {
    return {
      filterMenu: false
    };
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.$store.dispatch("progressStore/set", true);
      /* Grab filters */
      const filters = this.$store.getters["templatesStore/getFilters"];
      /* Store authorized user in store */
      await this.$store.dispatch("templatesStore/fetch", filters);
      this.$store.dispatch("progressStore/set", false);
      const Language_data_cd = {
        //BG 6/4/2021 (4 lines) code placed to populate valueListItems store
        id: [15]
      };
      await this.$store.dispatch("valueListsStore/items", Language_data_cd);
    },

    async filter(page) {
      this.filterMenu = false;
      this.page = page;
      this.initialize();
    },

    clearFilterColumns() {
      this.page = 1;
      this.d_name = "";
      this.f_type = "";
      this.f_section = "";
    },

    async clearFilter() {
      this.filterMenu = false;
      this.clearFilterColumns();
      this.initialize();
    },

    showFiltered() {
      if (this.d_name || this.f_type || this.f_section) {
        return true;
      }
      return false;
    },

    async openEntry(id, duplicateFlag) {
      await this.$store.dispatch("mergeCodesStore/clearValueList");
      await this.$store.dispatch("mergeCodeSectionsStore/valueList");
      if (duplicateFlag) {
        await this.$store.dispatch("templatesStore/duplicate", id);
      } else {
        await this.$store.dispatch("templatesStore/entry", id);
      }
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
